import React, { Component } from "react"
import styles from "./Navigation.module.css"
import Navigation from "./Navigation"

class NavigationContainer extends Component {
  state = {
    isOpen: false,
  }

  toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    return (
      <div className={styles.navContainer}>
        <div
          onClick={this.toggleOpen}
          className={styles.hamburger}
          role="button"
        >
          <div />
          <div />
          <div />
        </div>
        {this.state.isOpen ? <Navigation /> : null}
      </div>
    )
  }
}

export default NavigationContainer
