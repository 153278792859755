import {
  ContentItem,
  Elements,
  TypeResolver,
  DeliveryClient,
} from "@kentico/kontent-delivery"

const deliveryClient = new DeliveryClient({
  projectId: "a6be5214-4957-009c-eb54-94e1ba81c262",
  typeResolvers: [
    new TypeResolver("news", data => new News(data)),
    new TypeResolver("sales", data => new Sale(data)),
  ],
})

class News {
  constructor({ item }) {
    const firstImg = item.elements.img.value[0]
    this.header = item.system.name
    this.text = item.elements.body.value
    this.img = firstImg ? firstImg.url : ""
  }
}

class Sale {
  constructor({ item }) {
    const firstImg = item.elements.img.value[0]
    this.text = item.system.name
    this.img = firstImg ? firstImg.url : ""
  }
}

const getNews = () =>
  deliveryClient
    .items()
    .type("news")
    .toPromise()

const getSales = () =>
  deliveryClient
    .items()
    .type("sales")
    .toPromise()

export { getNews, getSales }
