import React from "react"
import styles from "./Events.module.css"

const Event = props => {
  return (
    <div className={styles.event}>
      <div className={styles.eventImgWrapper}>
        <img src={props.img} alt={props.header}></img>
      </div>
      <h4 className={styles.eventHeader}>
        {(props.header || "").toUpperCase()}
      </h4>
      <p
        className={styles.eventText}
        dangerouslySetInnerHTML={{
          __html: props.text,
        }}
      ></p>
    </div>
  )
}

export default Event
