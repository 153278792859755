import React, { Component } from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

class MenuSlider extends Component {
  state = {
    selectedItem: 0,
  }

  change = () => {
    if (this.state.selectedItem === this.props.imgsCount - 1) {
      this.setState({ selectedItem: 0 })
      return
    }
    this.setState({ selectedItem: this.state.selectedItem + 1 })
  }

  componentDidMount() {
    this.timer = setInterval(this.change, 4000)
  }

  componentWillUnmount() {
    if (!this.timer) return
    clearTimeout(this.timer)
  }

  render() {
    const { props } = this
    if (!props.children) return <div className={props.className}></div>
    return (
      <Carousel
        selectedItem={this.state.selectedItem}
        onClickItem={this.change}
        showArrows={false}
        showStatus={false}
        showIndicators
        showThumbs={false}
        useKeyboardArrow={false}
        className={props.className}
      >
        {props.children}
      </Carousel>
    )
  }
}

export default MenuSlider
