import React from "react"
import styles from "./Footer.module.css"
import { Facebook, Inst, VK } from "./icons"

const Footer = () => (
  <footer className={styles.footerContainer}>
    <div className={styles.footerWrapper}>
      <div className={styles.socialNetworks}>
        <a href="https://www.facebook.com/Raguonlinespb/" target="blank">
          <Facebook />
        </a>
        <a href="https://vk.com/ragu_online" target="blank">
          <VK />
        </a>
        <a href="https://www.instagram.com/ragu_online/" target="blank">
          <Inst />
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
