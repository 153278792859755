import React from "react"
import styles from "./Band.module.css"
import banquetsStyles from "../Banquets/Banquets.module.css"
import bandImg from "../../images/band-img.jpg"

const Band = props => (
  // <section id="#band" className="section miniSection containerWidth">
  <div className={banquetsStyles.banquetsWrapper}>
    <div className={banquetsStyles.textWrapper}>
      <p className="text">
        Для вашего праздника вы можете заказать уникальную веселую программу от
        команды профессиональных эстрадных и комедийных актеров «ГруппаРиска».
      </p>
      <p className="text">
        Актеры импровизирует в рамках выбранной для праздника темы, а гости
        оказываются внутри спектакля и становятся участниками неожиданных
        событий.
      </p>
      <p className="text">
        Актеры мастерской - лауреаты международных фестивалей и конкурсов,
        участники юмористических шоу.
      </p>
      <div className={styles.listWrapper}>
        <div>
          <h4>
            <strong>
              <font color="#C61313">Золотой состав:</font>
            </strong>
          </h4>
          <ul className={`list ${styles.membersList}`}>
            <li className="text">Климент Старкин</li>
            <li className="text">Иван Купреенко</li>
            <li className="text">Алексей Мамонов </li>
            <li className="text">Евгений Кузьмин</li>
            <li className="text">Наталья Демина</li>
          </ul>
        </div>
        <div>
          <h4>
            <strong>
              <font color="#C61313">
                Актеры театральной мастерской на телевидении:
              </font>
            </strong>
          </h4>
          <ul className={`list ${styles.membersList}`}>
            <li className="text">Шоу «ComedyБаттл»</li>
            <li className="text">Шоу «МясоРубка»</li>
            <li className="text">Спектакль «Папины сказки»</li>
            <li className="text">Шоу «Веселый вечер» и «МАСТЕР СМЕХА»</li>
            <li className="text">Шоу «Осторожно Задов»</li>
          </ul>
        </div>
      </div>
    </div>
    <div className={styles.bandImgSection}>
      <div className={styles.bandImgWrapper}>
        <img src={bandImg} alt="«ГруппаРиска»"></img>
      </div>
    </div>
  </div>
  // </section>
)

export default Band
