import React from "react"
import styles from "./Start.module.css"
import teleIcon from "../../images/tele-logo.svg"
import markerIcon from "../../images/map-marker.svg"
import timeIcon from "../../images/timeIcon.svg"

const Start = () => (
  <section id="#about" className={styles.startContainer}>
    <div className={styles.startWrapper}>
      <a className={styles.contactRow} href="tel:+78122443240">
        <div className={styles.iconWrapper}>
          <img src={teleIcon} alt="иконка телефона"></img>
        </div>
        <p>
          <span>8 (812) 244 32 40</span>
        </p>
      </a>
      <div className={styles.contactRow}>
        <div className={styles.iconWrapper}>
          <img src={markerIcon} alt="иконка отметки на карте"></img>
        </div>
        <address>
          <p>
            Санкт-Петербург,
            <br />
            Кадетская линия в.о., д.9
          </p>
        </address>
      </div>
      <div className={styles.contactRow}>
        <div className={[styles.iconWrapper, styles.timeIcon].join(" ")}>
          <img src={timeIcon} alt="иконка времени"></img>
        </div>
        <div className={styles.timeTextWrapper}>
          <p>
            Часы работы:
            <br />
            каждый день
            <br />с 11 до 23
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default Start
