import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon from "../images/favicon.png"
import backgroundImg from "../images/backgroundRagu.jpg"
import banquetsImg1 from "../images/banquets/banquets_01.jpg"
import banquetsImg3 from "../images/banquets/banquets_03.jpg"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            author
            url
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang: "ru-RU",
      }}
      title={site.siteMetadata.title}
    >
      <link rel="preload" href={backgroundImg} as="image" />
      {[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: site.siteMetadata.keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: "og:site_name",
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.url,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].map(curr => (
        <meta name={curr.name || curr.property} content={curr.content} />
      ))}

      <link rel="icon" href={favicon} />
      <meta content="business.business" property="og:type" />
      <meta content="59.9437048878" property="place:location:latitude" />
      <meta content="30.2884711895" property="place:location:longitude" />
      <meta content="Россия" property="business:contact_data:country" />
      <meta content="Россия" property="business:contact_data:country" />
      <meta
        content="Санкт-Петербург"
        property="business:contact_data:locality"
      />
      <meta
        content="Кадетская линия В. О., д. 9"
        property="business:contact_data:street_address"
      />
      <meta name="cmsmagazine" content="77089f8658a00b07e9ed8d2a03ac2540" />
      <script type="application/ld+json">
        {`{
          "@context": "https://schema.org/",
          "@id": "${site.siteMetadata.url}",
          "@type": "Restaurant",
          "name": "${site.siteMetadata.title}",
          "description": "${site.siteMetadata.description}",
          "url": "${site.siteMetadata.url}",
          "acceptsReservations": true,
          "telephone": "+7(812)-244-32-40",
          "address": {
            "@type": "PostalAddress",
            "addressCountry": "Россия",
            "addressRegion": "Санкт-Петербург",
            "addressLocality": "Санкт-Петербург",
            "streetAddress": "Кадетская линия, дом 9",
            "postalCode": "199004"
          },
          "image": [
            "https://ragu-spb.ru${backgroundImg}",
            "https://ragu-spb.ru${banquetsImg1}",
            "https://ragu-spb.ru${banquetsImg3}"
           ],
          "servesCuisine":"French",
          "priceRange": "$$",
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": 59.9437048878,
            "longitude": 30.2884711895
          },
          "menu": "https://ragu-spb.ru/#menu",
          "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
              ],
              "opens": "11:00",
              "closes": "23:00"
            }
          ]
        }`}
      </script>
    </Helmet>
  )
}

export default SEO
