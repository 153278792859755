import React from "react"
import Lottie from "lottie-react-web"
import animation from "../../images/animation.json"
import styles from "./Animation.module.css"

class Animation extends React.Component {
  state = {
    isLoaded: false,
  }

  componentDidMount() {
    setTimeout(this.endAnimation, 3000)
  }

  endAnimation = () => {
    this.setState({ isLoaded: true })
  }

  render() {
    return (
      <div
        id="___loader"
        className={[this.state.isLoaded && styles.hidden, styles.active].join(
          " "
        )}
      >
        <Lottie
          options={{
            animationData: animation,
            loop: false,
          }}
        />
      </div>
    )
  }
}

export default Animation
