import React from "react"

export const Facebook = () => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.00277753 13.4838C-0.00278967 5.80912 5.83535 0.00369415 13.5589 1.72154e-06C21.0867 -0.00369071 27 5.93282 27 13.4931C27 21.1179 21.0839 27.012 13.4318 27C5.82514 26.9871 -0.108567 21.0284 0.00277753 13.4838ZM10.9182 14.1688C10.9182 16.9704 10.9182 19.7453 10.9182 22.5681C12.2126 22.5681 13.4531 22.5681 14.741 22.5681C14.741 19.7111 14.741 16.9326 14.741 14.1199C15.5464 14.0626 16.3091 14.0082 17.1692 13.9472C17.1692 12.8524 17.1692 11.8047 17.1692 10.6813C16.4019 10.6813 15.6513 10.6813 14.8793 10.6813C14.8793 9.89295 14.8793 9.17939 14.8793 8.45383C15.6522 8.34121 16.3973 8.2332 17.1999 8.11689C17.1999 7.15409 17.2064 6.20513 17.1878 5.25618C17.1859 5.17956 17.0069 5.04202 16.9122 5.04294C15.7561 5.06048 14.5888 5.01156 13.4466 5.15371C12.0669 5.32541 11.1632 6.33899 10.9776 7.71257C10.9099 8.21382 10.8867 8.72153 10.8653 9.22739C10.8477 9.66864 10.8616 10.1117 10.8616 10.5853C10.4274 10.6342 10.0321 10.6961 9.63496 10.7173C9.36959 10.7311 9.24526 10.8068 9.25083 11.0958C9.26475 11.9266 9.26289 12.7574 9.25268 13.5882C9.2499 13.8411 9.34918 13.9436 9.59599 13.9722C10.0219 14.0211 10.445 14.0968 10.9182 14.1688Z"
      fill="#595959"
    />
  </svg>
)

export const Inst = () => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.00219828 13.6685C-0.127202 6.76975 5.46891 0.305209 13.4243 0.409447C20.7536 0.505527 26.6268 6.225 26.5302 13.8878C26.44 21.0866 20.7864 27.0489 13.0024 26.9384C5.32857 26.8296 -0.120823 20.521 0.00219828 13.6685ZM13.1764 21.1899C14.3912 21.1899 15.605 21.1917 16.8197 21.1899C19.0405 21.1854 20.5331 19.7831 20.5705 17.5787C20.6142 15.0734 20.5787 12.5672 20.5987 10.0618C20.6142 8.07136 18.9694 6.37093 16.9637 6.36549C14.4741 6.35824 11.9854 6.36368 9.49581 6.37909C7.40445 6.39268 5.85346 7.95081 5.84891 10.0319C5.84344 12.5228 5.84253 15.0145 5.84982 17.5053C5.8562 19.6916 7.34157 21.1745 9.53409 21.1899C10.7479 21.1971 11.9617 21.1899 13.1764 21.1899Z"
      fill="#595959"
    />
    <path
      d="M16.7632 12.8981C17.505 12.8981 18.1985 12.8981 18.9403 12.8981C18.9703 13.1863 19.0223 13.4664 19.0241 13.7465C19.0341 14.9239 19.0396 16.1022 19.0268 17.2797C19.0104 18.7644 18.1575 19.6209 16.6785 19.6282C14.3557 19.6391 12.0328 19.6391 9.71002 19.6291C8.33036 19.6237 7.47832 18.8296 7.44278 17.4591C7.4045 15.9681 7.43367 14.4752 7.43367 12.947C8.22738 12.947 8.99103 12.947 9.82393 12.947C9.79932 13.2625 9.7811 13.5715 9.75011 13.8788C9.46762 16.6497 12.1376 18.2876 14.2554 17.7193C15.9823 17.2561 17.0776 15.4542 16.8225 13.5407C16.7951 13.3377 16.7842 13.131 16.7632 12.8981Z"
      fill="#595959"
    />
    <path
      d="M19.0004 11.2022C17.9297 11.2022 16.8872 11.2375 15.8483 11.1859C15.4082 11.1641 14.9571 11.0055 14.5452 10.8287C13.6977 10.4662 12.8867 10.497 12.0356 10.826C11.4915 11.0363 10.9092 11.2149 10.3324 11.2584C9.39926 11.3291 8.45609 11.2774 7.52478 11.2774C7.15662 10.0284 7.50108 8.78931 8.416 8.28807C8.78415 8.08684 9.24434 7.96629 9.66535 7.96085C12.031 7.93003 14.3967 7.94272 16.7623 7.94726C17.9798 7.94998 18.8036 8.62797 18.9886 9.82988C19.0587 10.2822 19.0004 10.7535 19.0004 11.2022ZM16.0114 10.6864C16.5126 10.6864 16.9482 10.6864 17.3838 10.6864C18.0928 10.6855 18.0928 10.6855 18.2541 9.99666C18.4145 9.30688 18.2787 8.88993 17.7902 8.57268C17.3738 8.30257 16.5573 8.33248 16.1509 8.64701C16.0807 8.70139 16.0187 8.81469 16.0169 8.90262C16.006 9.47184 16.0114 10.042 16.0114 10.6864Z"
      fill="#595959"
    />
    <path
      d="M12.1075 14.1163C12.113 13.3204 12.4848 12.9515 13.2767 12.9542C14.0759 12.9579 14.5297 13.402 14.5288 14.1788C14.527 14.9375 13.9939 15.4913 13.2648 15.4904C12.5504 15.4895 12.1021 14.9565 12.1075 14.1163Z"
      fill="#595959"
    />
  </svg>
)

export const VK = () => (
  <svg height="27" width="27" version="1.1" viewBox="0 0 100 100">
    <path
      d="M50,3.84C24.506,3.84,3.84,24.506,3.84,50S24.506,96.16,50,96.16S96.16,75.494,96.16,50S75.494,3.84,50,3.84  z M74.808,67.517c-1.97,1.232-9.975,1.599-11.913,0.191c-1.063-0.772-2.035-1.738-2.958-2.658c-0.646-0.643-1.379-0.955-1.934-1.653  c-0.453-0.571-0.764-1.245-1.241-1.809c-0.805-0.946-2.044-1.751-3.024-0.595c-1.476,1.739,0.232,5.154-1.736,6.453  c-0.66,0.436-1.326,0.562-2.151,0.5l-1.827,0.083c-1.073,0.021-2.773,0.031-3.994-0.189c-1.362-0.246-2.487-0.99-3.719-1.536  c-2.338-1.037-4.565-2.45-6.26-4.407c-4.613-5.33-10.809-12.66-13.216-19.361c-0.495-1.377-1.803-4.104-0.563-5.285  c1.686-1.225,9.961-1.57,11.252,0.324c0.524,0.77,0.855,1.896,1.226,2.769c0.462,1.088,0.712,2.113,1.434,3.076  c0.639,0.854,1.11,1.713,1.604,2.649c0.555,1.051,1.079,2.059,1.754,3.026c0.458,0.658,1.669,1.967,2.434,2.064  c1.868,0.239,1.752-4.3,1.613-5.408c-0.133-1.068-0.167-2.201-0.132-3.284c0.03-0.924,0.113-2.226-0.434-2.98  c-0.892-1.23-2.879-0.31-3.034-1.963c0.328-0.469,0.259-0.885,2.45-1.607c1.726-0.567,2.841-0.549,3.979-0.458  c2.32,0.186,4.78-0.442,7.02,0.31c2.14,0.721,1.809,3.762,1.737,5.584c-0.097,2.487,0.007,4.915,0,7.44  c-0.003,1.149-0.049,2.268,1.353,2.174c1.314-0.087,1.448-1.193,2.075-2.105c0.872-1.272,1.673-2.562,2.561-3.829  c1.197-1.713,1.559-3.638,2.686-5.393c0.403-0.627,0.75-1.963,1.371-2.459c0.47-0.377,1.363-0.18,1.932-0.18h1.353  c1.035,0,2.091-0.008,3.155,0.029c1.532,0.053,3.248-0.299,4.767-0.084c6.555,0.925-8.239,14.938-7.469,17.44  c0.532,1.728,3.907,3.659,5.125,5.074C73.701,61.345,78.679,65.092,74.808,67.517z"
      fill="#595959"
    />
  </svg>
)
