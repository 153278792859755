import React, { Component } from "react"
import styles from "./Navigation.module.css"

class Navigation extends Component {
  state = {
    rerender: false,
    elements: [],
  }
  componentDidMount() {
    window.addEventListener("scroll", this.updateState)
    this.loadElements()
  }

  updateState = () => {
    this.setState({ rerender: !this.state.rerender })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateState)
  }

  loadElements = () => {
    const elements = [
      "#about",
      "#menu",
      "#events",
      "#sales",
      "#banquets",
      "#band",
    ].map(curr => document.getElementById(curr))

    this.setState({ elements })
  }

  isElementInViewport = el => {
    if (!el) return
    var rect = el.getBoundingClientRect()
    return rect.bottom > 5 && rect.top < 5
  }

  getClassName = number => {
    return this.isElementInViewport(this.state.elements[number])
      ? styles.activeNavItem
      : ""
  }

  render() {
    return (
      <nav>
        <ul className={styles.navigationList}>
          <li className={this.getClassName(0)}>
            <a href="#about">О нас</a>
          </li>
          <li className={this.getClassName(1)}>
            <a href="#menu">Меню</a>
          </li>
          <li className={this.getClassName(2)}>
            <a href="#events">Новости</a>
          </li>
          <li className={this.getClassName(3)}>
            <a href="#sales">Акции</a>
          </li>
          <li className={this.getClassName(4)}>
            <a href="#banquets">Банкеты</a>
          </li>
        </ul>
      </nav>
    )
  }
}

export default Navigation
