import React, { Component } from "react"
import styles from "./Sales.module.css"
import { getSales } from "../CMS"

class Sales extends Component {
  state = {
    sales: null,
  }

  async componentDidMount() {
    let sales
    try {
      sales = await getSales()
    } catch (e) {
      console.warn(e)
    }
    if (!sales) return
    this.setState({ sales: sales.items })
  }

  renderSale(sale, i) {
    return (
      <div key={i} className={styles.sale}>
        <img src={sale.img} alt={sale.text}></img>
      </div>
    )
  }

  renderSales() {
    return (
      <div className={styles.salesWrapper}>
        {this.state.sales.map((sale, i) => this.renderSale(sale, i))}
        <div className={[styles.BGCircle, styles.leftCircle].join(" ")} />
        <div className={[styles.BGCircle, styles.centerCircle].join(" ")} />
        <div className={[styles.BGCircle, styles.rightCircle].join(" ")} />
      </div>
    )
  }

  render() {
    if (!this.state.sales || !this.state.sales.length)
      return <section id="#sales"></section>
    return (
      <section id="#sales" className="section miniSection containerWidth">
        <h2 className="sectionHeader">Акции</h2>
        {this.renderSales()}
      </section>
    )
  }
}

export default Sales
