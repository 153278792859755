import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Start from "../components/Start"
import Events from "../components/Events"
import Sales from "../components/Sales"
import InteractiveMenu from "../components/InteractiveMenu"
import Banquets from "../components/Banquets"
import Animation from "../components/Animation"
import BookForm from "../components/BookForm"

const IndexPage = () => (
  <Layout>
    <main>
      <Animation />
      <SEO />
      <Start />
      <InteractiveMenu />
      <Events />
      <Sales />
      <Banquets />
      <BookForm pageForm closeForm={() => {}} />
    </main>
  </Layout>
)

export default IndexPage
