import React from "react"
import Header from "./Header"
import "./layout.css"
import Footer from "./Footer"
import styles from "./Footer/Footer.module.css"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
      <div className={styles.raguRest}>
        <span>RAGU RESTAURANT</span>
        <div className={styles.redCircle} />
      </div>
    </>
  )
}

export default Layout
