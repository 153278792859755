import React, { Component } from "react"
import Event from "./Event"
import styles from "./Events.module.css"
import { getNews } from "../CMS"

class Events extends Component {
  state = {
    events: null,
  }

  async componentWillMount() {
    let news
    try {
      news = await getNews()
    } catch (e) {
      console.error(e)
    }
    if (!news) return
    this.setState({ events: news.items })
  }

  render() {
    if (!this.state.events || !this.state.events.length)
      return <section id="#events"></section>
    return (
      <section id="#events" className="section miniSection containerWidth">
        <h2 className="sectionHeader">Наши новости</h2>
        <div className={styles.eventsWrapper}>
          {this.state.events.map((event, i) => (
            <Event {...event} key={i}></Event>
          ))}
        </div>
      </section>
    )
  }
}

export default Events
