import React from "react"
import styles from "./Header.module.css"
import logo from "../../images/logo.svg"
import Navigation from "../Navigation"
import { NotMobile } from "../mediaQuery"
import BookButton from "../BookButton"

const Header = () => (
  <header className={styles.headerContainer}>
    <div className={styles.logoAndNav}>
      <div className={styles.logoWrapper}>
        <img src={logo} alt="логотип ресторана Рагу" />
      </div>
      <NotMobile>
        <Navigation />
      </NotMobile>
    </div>
    <div className={styles.bookButtonFix}>
      <a href="#bookform">
        <BookButton />
      </a>
    </div>{" "}
  </header>
)

export default Header
