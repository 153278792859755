import React, { Component } from "react"
import Form from "./BookForm"
import styles from "./BookForm.module.css"

class BookForm extends Component {
  state = {
    submitting: false,
  }

  sendForm = async data => {
    this.setState({ submitting: true })
    try {
      await window.fetch(process.env.GATSBY_MAIL_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(data),
        isBase64Encoded: true,
        mode: "no-cors", // 'cors' by default,
      })

      alert("Заявка успешно отправлена")
    } catch (e) {
      console.error(e)
      alert("Произошла ошибка, повторите позднее")
    }

    if (this.form && this.form.reset) {
      this.form.reset()
    }

    this.setState({ submitting: false })
    this.props.closeForm()
  }

  getRef = ref => {
    this.form = ref
  }

  render() {
    return (
      <div className="containerWidth" id="#bookform">
        <h2 className="sectionHeader">Форма брони</h2>
        <div className={styles.pageForm}>
          <Form
            sendForm={this.sendForm}
            submitting={this.state.submitting}
            getRef={this.getRef}
          />
        </div>
      </div>
    )
  }
}

export default BookForm
