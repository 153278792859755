import React from "react"
import styles from "./MenuList.module.css"

const MenuList = props => {
  return (
    <div className={styles.menuWrapper}>
      <div className={styles.headerGroup}>
        <h3 className={styles.menuHeader}>{props.header}</h3>
        <p className={styles.addInfo}>
          {props.addInfo && props.addInfo.map(item => <span>{item}</span>)}
        </p>
      </div>
      <ol>
        {(props.list || []).map((item, i) => (
          <MenuListItem key={i} {...item} />
        ))}
      </ol>
    </div>
  )
}

const MenuListItem = props => {
  return (
    <div className={styles.menuListItem}>
      <div className={styles.itemTextWrapper}>
        <h4 className={styles.itemName}>{props.name}</h4>
        <span className={styles.itemDescription}>{props.description}</span>
      </div>
      <span className={styles.itemPrice}>{props.price}</span>
    </div>
  )
}

export default MenuList
