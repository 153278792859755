import React, { Component } from "react"
import styles from "./MenuSlider.module.css"
import Slider from "../Slider"

class MenuSlider extends Component {
  state = {
    imgs: null,
  }

  componentDidMount() {
    const imgs = this.getImages()
    this.setState({ imgs })
  }

  getImages = () =>
    [...new Array(this.props.imgsCount)].map((curr, i) => {
      return (
        <div className={styles.imgWrapper} key={i}>
          <img
            src={`https://storage.yandexcloud.net/raguimgs/${this.props.type}/00000${i}.jpg`}
            alt="фотография блюда из категории"
          />
        </div>
      )
    })

  render() {
    return (
      <Slider
        imgsCount={this.props.imgsCount}
        className={styles.carouselWrapper}
      >
        {this.state.imgs}
      </Slider>
    )
  }
}

export default MenuSlider
