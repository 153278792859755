import React from "react"
import styles from "./BookForm.module.css"
import BookButton from "../BookButton"
import InputMask from "react-input-mask"
import redLogo from "../../images/redlogo.svg"

import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

class BookForm extends React.Component {
  state = {
    time: null,
    date: null,
  }

  // isDateInputEnable = () => {
  //   var input = document.createElement("input")
  //   input.setAttribute("type", "date")

  //   var notADateValue = "not-a-date"
  //   input.setAttribute("value", notADateValue)

  //   return input.value !== notADateValue
  // }

  getDateTimeInputs = () => {
    // if (this.isDateInputEnable()) {
    //   return (
    //     <>
    //       <input
    //         placeholder="Дата бронирования"
    //         className={styles.input}
    //         type="date"
    //         name="date"
    //         required
    //         disabled={this.props.submitting}
    //       />
    //       <input
    //         placeholder="Время бронирования"
    //         className={styles.input}
    //         type="time"
    //         name="time"
    //         required
    //         disabled={this.props.submitting}
    //       />
    //     </>
    //   )
    // }

    return (
      <>
        <DatePicker
          selected={this.state.date}
          onChange={value => this.handleChange(value, "date")}
          placeholderText="Дата бронирования"
          minDate={new Date()}
          className={styles.input}
          type="date"
          name="date"
          required
          disabled={this.props.submitting}
          dateFormat="yyyy-MM-dd"
        />
        <DatePicker
          selected={this.state.time}
          onChange={value => this.handleChange(value, "time")}
          placeholderText="Время бронирования"
          className={styles.input}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={10}
          timeCaption="Время"
          timeFormat="HH:mm"
          dateFormat="HH:mm"
          type="time"
          name="time"
          required
        />
      </>
    )
  }

  handleChange = (value, type) => {
    this.setState({ [type]: value })
  }

  handleSubmit = ev => {
    ev.preventDefault()
    ev.stopPropagation()

    this.props.sendForm({
      sendMail: true,
      contactData: ev.currentTarget.tel.value,
      comment: ev.currentTarget.text.value,
      date: ev.currentTarget.date.value + " " + ev.currentTarget.time.value,
    })
  }

  render() {
    const { props } = this
    return (
      <>
        <div className={styles.circle}>
          <img src={redLogo} alt="логотип ресторана" />
        </div>
        <div className={styles.formWrapper}>
          <form
            className={styles.formContent}
            onSubmit={this.handleSubmit}
            ref={ref => props.getRef(ref)}
          >
            <div className={styles.inputs}>
              <div className={styles.firstInputsRow}>
                {this.getDateTimeInputs()}
                <InputMask
                  mask="+7 (999) 999-99-99"
                  name="tel"
                  placeholder="Ваш телефон"
                  className={styles.input}
                  required
                  disabled={props.submitting}
                />
              </div>
              <textarea
                className={styles.textArea}
                name="text"
                placeholder="Комментарий к бронированию"
                disabled={props.submitting}
              />
            </div>
            <div className={styles.sendButton}>
              <BookButton disabled={props.submitting} type="submit" />
            </div>
          </form>
        </div>
      </>
    )
  }
}

export default BookForm
