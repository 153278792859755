import React from "react"
import styles from "./BookButton.module.css"

const BookButton = props => {
  return (
    <button
      onClick={props.onClick ? props.onClick : () => {}}
      className={[
        styles.bookButtonWrapper,
        props.disabled && styles.disabled,
      ].join(" ")}
      type={props.type || "button"}
    >
      <span>Забронировать</span>
    </button>
  )
}

export default BookButton
