import React, { Component } from "react"
import styles from "./InteractiveMenu.module.css"
import { Desktop } from "../mediaQuery"

class MenuItem extends Component {
  handleClick = () => {
    this.props.onClick(this.props.type)
  }

  render() {
    const { props } = this
    return (
      <div className={[styles.menuItem, this.props.className].join(" ")}>
        <div onClick={this.handleClick}>
          <div className={styles.tooltip}>
            <h3 className={styles.tooltipText}>{props.name}</h3>
          </div>
          <img
            className={styles.menuItemThumbnail}
            src={props.img}
            alt={props.name}
          />
        </div>
        <Desktop>
          <div style={{ display: "none" }}>
            <img
              src={`https://storage.yandexcloud.net/raguimgs/${this.props.type}/000000.jpg`}
              alt="фотография блюда из категории"
            />
            <img
              src={`https://storage.yandexcloud.net/raguimgs/${this.props.type}/000001.jpg`}
              alt="фотография блюда из категории"
            />
          </div>
        </Desktop>
      </div>
    )
  }
}

export default MenuItem
