import React, { Component } from "react"
import Modal from "react-modal"
import MenuSlider from "../MenuSlider"
import MenuList from "../MenuList"
import styles from "./MenuModal.module.css"
import prices from "../../extra/prices.json"
import times from "../../images/times.svg"
import { Desktop } from "../mediaQuery"

class MenuModal extends Component {
  render() {
    const { props } = this
    const data = prices[props.type] || prices.ragu
    return (
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.toggleOpen}
        className={styles.modalContent}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.modalContentContainer}>
          <div
            onClick={props.toggleOpen}
            className={styles.closeButtonWrapper}
            role="button"
          >
            <img src={times} alt="Закрыть модальное окно" />
          </div>
          <div className={styles.modalContentWrapper}>
            <Desktop>
              <MenuSlider imgsCount={data.imgsCount} type={props.type} />
            </Desktop>
            <MenuList
              list={data.list}
              header={data.header}
              addInfo={data.addInfo}
            />
          </div>
        </div>
      </Modal>
    )
  }
}

export default MenuModal
