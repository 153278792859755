import React from "react"
import styles from "./Banquets.module.css"
import banquetsImg1 from "../../images/banquets/banquets_01.jpg"
import banquetsImg2 from "../../images/banquets/banquets_02.jpg"
import banquetsImg3 from "../../images/banquets/banquets_03.jpg"
import Slider from "../Slider"
import Band from "../Band"

const Banquets = props => (
  <section
    id="#banquets"
    className={`containerWidth ${styles.banquetsContainer}`}
  >
    <h2 className="sectionHeader">Банкеты</h2>
    <div className={styles.banquetsWrapper}>
      <div className={styles.textWrapper}>
        <p className={["text", styles.preListText].join(" ")}>
          Организация банкетного обслуживания мероприятий любого формата.
        </p>
        <ul className="list">
          <li>
            <p className="text">Разнообразие вариантов рассадки</p>
          </li>
          <li>
            <p className="text">Размещение в банкетном зале до 60 гостей</p>
          </li>
          <li>
            <p className="text">Возможна аренда ресторана под закрытие.</p>
          </li>
          <li>
            <p className="text">
              Предоставление необходимого технического оборудования для
              проведения развлекательной программы.
            </p>
          </li>
        </ul>
        <p className="text">
          <strong>
            Мы рады предложить вам множество сетов банкетного меню
            <font color="#C61313"> от 2 500 руб. на одного гостя.</font>
          </strong>
        </p>
      </div>
      <div className={styles.sliderContainer}>
        <Slider imgsCount={3} className={styles.carouselWrapper}>
          <div className={styles.imgWrapper}>
            <img src={banquetsImg1} alt="Интерьер ресторана" />
          </div>
          <div className={styles.imgWrapper}>
            <img src={banquetsImg2} alt="Интерьер ресторана" />
          </div>
          <div className={styles.imgWrapper}>
            <img src={banquetsImg3} alt="Интерьер ресторана" />
          </div>
        </Slider>
      </div>
    </div>
    <Band></Band>
  </section>
)

export default Banquets
