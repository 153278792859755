import React, { Component } from "react"
import styles from "./InteractiveMenu.module.css"
import MenuItem from "./MenuItem"
import MenuModal from "../MenuModal"

import breakfast from "../../images/plates/breakfast_min.png"
import cake from "../../images/plates/cake_min.png"
import main from "../../images/plates/main_min.png"
import pepper from "../../images/plates/pepper_min.png"
import pizza from "../../images/plates/pizza_min.png"
import salad from "../../images/plates/salad_min.png"
import soup from "../../images/plates/soup_min.png"
import grill from "../../images/plates/meat_min.png"
import ragu from "../../images/plates/ragu_min.png"

import sweetPepperSmall from "../../images/plates-from-figma/sweet-pepper-01.png"
import sweetPepperMedium from "../../images/plates-from-figma/sweet-pepper-half.jpg"
import cherry1 from "../../images/plates-from-figma/cherry1.jpg"
import cherry2 from "../../images/plates-from-figma/cherry2.jpg"
import cherry05 from "../../images/plates-from-figma/cherry05.png"
import cherry15 from "../../images/plates-from-figma/cherry15.jpg"

import rosemary1 from "../../images/rosemary/rosemary1.png"
import rosemary2 from "../../images/rosemary/rosemary2.png"
import rosemary3 from "../../images/rosemary/rosemary3.png"
import rosemary4 from "../../images/rosemary/rosemary4.png"

class InteractiveMenu extends Component {
  state = {
    isOpen: false,
    type: null,
  }

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  openModal = type => {
    this.setState({ isOpen: true, type })
  }

  render() {
    return (
      <section id="#menu" className={`${styles.menuContainer} section`}>
        <div className={styles.menuWrapper}>
          <MenuItem
            img={cake}
            name="ДЕСЕРТЫ"
            className={styles.cake}
            onClick={this.openModal}
            type="cake"
          />
          <MenuItem
            img={salad}
            name="САЛАТЫ"
            className={styles.salad}
            onClick={this.openModal}
            type="salad"
          />
          <MenuItem
            img={pizza}
            name="ТЕСТО"
            className={styles.pizza}
            onClick={this.openModal}
            type="pizza"
          />
          <MenuItem
            img={soup}
            name="СУПЫ"
            className={styles.soup}
            onClick={this.openModal}
            type="soup"
          />
          <MenuItem
            img={grill}
            name="ГРИЛЬ"
            className={styles.grill}
            onClick={this.openModal}
            type="grill"
          />
          <MenuItem
            img={ragu}
            name="РАГУ"
            className={styles.ragu}
            onClick={this.openModal}
            type="ragu"
          />
          <MenuItem
            img={breakfast}
            name="ЗАВТРАКИ"
            className={styles.breakfast}
            onClick={this.openModal}
            type="breakfast"
          />
          <MenuItem
            img={pepper}
            name="ЗАКУСКИ"
            className={styles.pepper}
            onClick={this.openModal}
            type="snacks"
          />
          <MenuItem
            img={main}
            name="ГОРЯЧЕЕ"
            className={styles.main}
            onClick={this.openModal}
            type="main"
          />
          <div
            className={`${styles.additionElement} ${styles.sweetPepperMedium}`}
          >
            <img src={sweetPepperMedium}></img>
          </div>
          <div
            className={`${styles.additionElement} ${styles.sweetPepperSmall}`}
          >
            <img src={sweetPepperSmall}></img>
          </div>
          <div className={` ${styles.additionElement} ${styles.cherry1}`}>
            <img src={cherry1}></img>
          </div>
          <div className={`${styles.additionElement} ${styles.cherry2}`}>
            <img src={cherry2}></img>
          </div>
          <div className={`${styles.additionElement} ${styles.cherry05}`}>
            <img src={cherry05}></img>
          </div>
          <div className={`${styles.additionElement} ${styles.cherry15}`}>
            <img src={cherry15}></img>
          </div>
          <div className={`${styles.additionElement} ${styles.rosemary1}`}>
            <img src={rosemary1}></img>
          </div>
          <div className={`${styles.additionElement} ${styles.rosemary2}`}>
            <img src={rosemary2}></img>
          </div>
          <div className={`${styles.additionElement} ${styles.rosemary3}`}>
            <img src={rosemary3}></img>
          </div>
          <div className={`${styles.additionElement} ${styles.rosemary4}`}>
            <img src={rosemary4}></img>
          </div>
        </div>
        <MenuModal
          isOpen={this.state.isOpen}
          toggleOpen={this.toggleModal}
          type={this.state.type}
        ></MenuModal>
      </section>
    )
  }
}

export default InteractiveMenu
